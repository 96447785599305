import { useEffect, FunctionComponent } from "react";
import { navigate } from "gatsby"


const Missing: FunctionComponent = (): JSX.Element => {
  useEffect(() => {
    navigate(`https://tryhabitual.com/404`)
  }, [])
  return null;
};


export default Missing;